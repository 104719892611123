import {
  DASHBOARD,
  NOTIFICATION,
  PATIENT,
  PATIENT_LIST,
} from "../constants/const";

export function modifyNavBar(pathName, setCurrentTab) {
  switch (pathName) {
    case DASHBOARD:
      setCurrentTab(DASHBOARD);
      break;
    case PATIENT_LIST:
      setCurrentTab(PATIENT_LIST);
      break;
    case PATIENT:
      setCurrentTab(PATIENT);
      break;
    case NOTIFICATION:
      setCurrentTab(NOTIFICATION);
      break;
    default:
      break;
  }
}
