import "./index.css";
import * as React from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useState } from "react";
import {
  dummyData,
  LINE_CHART,
  PIE_CHART,
  WEEKLY_CHART,
} from "../../../../constants/const";
import Mask from "../../../../public_components/mask/mask";

export default function TitleBar(props) {
  const {
    positionIndex,
    title,
    setTitle,
    setDate,
    setValue,
    setLegend,
    displayContainer,
    setDisplayContainer,
    clickable = true,
  } = props;

  const [open, setOpen] = useState(false);

  //useEffect to auto scroll the dropdown menu to selected item
  React.useEffect(() => {
    if (open) {
      let index = 0;
      for (let i = 0; i < dummyData.length; i++) {
        if (title === dummyData[i].title) {
          index = i;
        }
      }
      document
        .getElementById(
          `positionIndex` + positionIndex + `dropdownMenu` + index
        )
        ?.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "start",
        });
      document
        .getElementById(
          `positionIndex` + positionIndex + `dropdownMenu` + index
        )
        .style.setProperty("background", "gray");
    }
  }, [open]);

  return (
    <>
      <div className="patient-dataBlock-title">
        <div>{title}</div>
        {open ? (
          <KeyboardArrowUpIcon
            className="arrow"
            style={{
              cursor: clickable ? "pointer" : "initial",
              display: `${
                positionIndex === displayContainer.length - 1
                  ? "normal"
                  : "none"
              }`,
            }}
            onClick={() => {
              if (clickable) {
                setOpen(false);
              }
            }}
          ></KeyboardArrowUpIcon>
        ) : (
          <KeyboardArrowDownIcon
            className="arrow"
            style={{
              cursor: clickable ? "pointer" : "initial",
              display: `${
                positionIndex === displayContainer.length - 1
                  ? "normal"
                  : "none"
              }`,
            }}
            onClick={() => {
              if (clickable) {
                setOpen(true);
              }
            }}
          ></KeyboardArrowDownIcon>
        )}
      </div>
      {open ? (
        <>
          {/* overlay */}
          {/* <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100vw",
              height: "100vh",
              backgroundColor: "rgba(0,0,0,0)",
              zIndex: "5",
            }}
            onClick={() => {
              setOpen(false);
            }}
          ></div> */}
          <div
            className="dropdown"
            style={{
              // maxHeight: `${
              //   displayContainer[positionIndex].type === WEEKLY_CHART
              //     ? 120
              //     : 269
              // }px`,
              top: "31px",
            }}
          >
            {dummyData.map((item, index) => {
              return (
                <div
                  id={`positionIndex` + positionIndex + `dropdownMenu` + index}
                  className="menu"
                  key={index}
                  onClick={() => {
                    if (clickable) {
                      setOpen(false);
                      setTitle(item.title);
                      setDate(item.date);
                      setValue(item.value);
                      if (
                        displayContainer[positionIndex].type !== WEEKLY_CHART
                      ) {
                        setLegend(item.legend);
                      }
                      let temp = [...displayContainer];
                      temp[positionIndex] = item;
                      setDisplayContainer(temp);
                    }
                  }}
                >
                  {item.title}
                </div>
              );
            })}
          </div>
          <Mask
            maskOpen={open}
            setMaskOpen={setOpen}
            setOtherOpen={setOpen}
          ></Mask>
        </>
      ) : (
        ""
      )}
    </>
  );
}
