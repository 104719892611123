import { useState, useEffect } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  useLocation,
} from "react-router-dom";
import "./App.css";
import "./constants/const";
import {
  DASHBOARD,
  NOTIFICATION,
  PATIENT,
  PATIENT_LIST,
} from "./constants/const";
import * as React from "react";
import Notification from "./pages/Notification";
import Dashboard from "./pages/Dashboard";
import AccessibleIcon from "@mui/icons-material/Accessible";
import HomeIcon from "@mui/icons-material/Home";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import List from "./pages/Patient_List";
import Patient from "./pages/Patient";
import { Badge, ListItemIcon } from "@mui/material";

function App() {
  const [currentTab, setCurrentTab] = useState(window.location.pathname);

  function Navigation() {
    return (
      <nav className="nav">
        <div className="nav-header">ROAMM-EHR</div>
        <Link
          to={{
            pathname: "/",
          }}
          className="nav-menu"
          onClick={() => {
            setCurrentTab(DASHBOARD);
          }}
          style={{ textDecoration: "none" }}
        >
          <div className="nav-icon">
            <HomeIcon
              className={
                currentTab === DASHBOARD ? "icon clicked" : "icon unclicked"
              }
            ></HomeIcon>
          </div>
          <div
            className={
              currentTab === DASHBOARD
                ? "nav-title clicked"
                : "nav-title unclicked"
            }
          >
            <div>Dashboard</div>
          </div>
        </Link>
        <Link
          to="/patientList"
          className="nav-menu"
          onClick={() => {
            setCurrentTab(PATIENT_LIST);
          }}
          style={{ textDecoration: "none" }}
        >
          <div className="nav-icon">
            <FormatListBulletedIcon
              className={
                currentTab === PATIENT_LIST ? "icon clicked" : "icon unclicked"
              }
            ></FormatListBulletedIcon>
          </div>
          <div
            className={
              currentTab === PATIENT_LIST
                ? "nav-title clicked"
                : "nav-title unclicked"
            }
          >
            <div>Patients List</div>
          </div>
        </Link>
        <Link
          to="/patient"
          className="nav-menu"
          onClick={() => {
            setCurrentTab(PATIENT);
          }}
        >
          <div className="nav-icon">
            <AccessibleIcon
              className={
                currentTab === PATIENT ? "icon clicked" : "icon unclicked"
              }
            ></AccessibleIcon>
          </div>
          <div
            className={
              currentTab === PATIENT
                ? "nav-title clicked"
                : "nav-title unclicked"
            }
          >
            <div>Patient</div>
          </div>
        </Link>
        <Link
          to="/notification"
          className="nav-menu"
          onClick={() => {
            setCurrentTab(NOTIFICATION);
          }}
          style={{ textDecoration: "none" }}
        >
          <div className="nav-icon">
            <Badge
              badgeContent={4}
              color="warning"
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              className="icon"
            >
              <NotificationsNoneIcon
                className={
                  currentTab === NOTIFICATION
                    ? "icon clicked"
                    : "icon unclicked"
                }
                style={{
                  right: "0",
                  top: "0",
                }}
              ></NotificationsNoneIcon>
            </Badge>
          </div>
          <div
            className={
              currentTab === NOTIFICATION
                ? "nav-title clicked"
                : "nav-title unclicked"
            }
          >
            <div>Notification</div>
          </div>
        </Link>
      </nav>
    );
  }

  return (
    <div className="page">
      <BrowserRouter>
        <Navigation></Navigation>
        <Routes>
          <Route
            path="/"
            element={<Dashboard setCurrentTab={setCurrentTab}></Dashboard>}
          ></Route>
          <Route
            path="/patientList"
            element={<List setCurrentTab={setCurrentTab}></List>}
          ></Route>
          <Route
            path="/notification"
            element={
              <Notification setCurrentTab={setCurrentTab}></Notification>
            }
          ></Route>
          <Route
            path="/patient"
            element={<Patient setCurrentTab={setCurrentTab}></Patient>}
          ></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
