import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  textAlign: "center",
  p: 4,
};

export default function BasicModal(props) {
  const { modalOpen, setModalOpen, Title } = props;

  const handleClose = () => setModalOpen(false);

  return (
    <div>
      <Modal
        open={modalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {Title}
          </Typography>
          <Typography
            id="modal-modal-description"
            style={{ textAlign: "center", cursor: "pointer" }}
            sx={{ mt: 2 }}
            onClick={handleClose}
          >
            Close
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}
