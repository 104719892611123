import "./index.css";
import * as React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  ArcElement,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { dummyData, NOTIFICATION, PATIENT_LIST } from "../../constants/const";
import { Pie } from "react-chartjs-2";
import { Alert, Button } from "@mui/material";

import { DataGrid } from "@mui/x-data-grid";
import SendIcon from "@mui/icons-material/Send";
import { IconButton } from "@mui/material";
import { PATIENT, PROCEDURE_TYPE } from "../../constants/const";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { FormatListBulleted } from "@mui/icons-material";
import { modifyNavBar } from "../../util/commonFunctions";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
ChartJS.register(ArcElement, Tooltip, Legend);
export const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
      position: "top",
    },
    title: {
      display: false,
      text: "Chart.js Bar Chart",
    },
  },
};

const labels = [
  PROCEDURE_TYPE.LEFT_THA.abbr,
  PROCEDURE_TYPE.LEFT_TKA.abbr,
  PROCEDURE_TYPE.RIGHT_THA.abbr,
  PROCEDURE_TYPE.RIGHT_TKA.abbr,
];

export const data = {
  labels,
  datasets: [
    {
      label: "male patients",
      data: [129, 69, 60, 23],
      backgroundColor: "lightblue",
    },
    {
      label: "female patients",
      data: [80, 103, 80, 52],
      backgroundColor: "rgba(255, 99, 132, 0.5)",
    },
  ],
};

function Dashboard(props) {
  const { setCurrentTab } = props;

  useEffect(() => {
    modifyNavBar(window.location.pathname, setCurrentTab);
  }, []);

  const rows = [
    {
      id: 1,
      patientName: "Patient A",
      surgery: "name1",
      type: PROCEDURE_TYPE.LEFT_TKA.abbr,
      date: "9/14/2022",
      button: "first row",
    },
    {
      id: 2,
      patientName: "Patient B",
      surgery: "name2",
      type: PROCEDURE_TYPE.LEFT_THA.abbr,
      date: "9/23/2022",
    },
    {
      id: 3,
      patientName: "Patient C",
      surgery: "name3",
      type: PROCEDURE_TYPE.RIGHT_THA.abbr,
      date: "10/7/2022",
    },
  ];

  const columns = [
    {
      field: "patientName",
      headerName: "Name",
      flex: 1,
      align: "center",
      headerAlign: "center",
      headerClassName: "table-header",
    },
    {
      field: "type",
      headerName: "Type",
      flex: 0.5,
      align: "center",
      headerAlign: "center",
      headerClassName: "table-header",
    },
    {
      field: "date",
      headerName: "Date",
      flex: 0.5,
      align: "center",
      headerAlign: "center",
      headerClassName: "table-header",
    },
    {
      field: "button",
      headerName: "",
      flex: 0.2,
      align: "center",
      headerAlign: "center",
      headerClassName: "table-header",
      sortable: false,
      disableColumnMenu: true,
      renderCell: (cellValues) => {
        return (
          <Link
            to="/patient"
            state={{
              patientName: cellValues.row.patientName,
              type: cellValues.row.type,
              date: cellValues.row.date,
              surgery: cellValues.row.surgery,
            }}
            onClick={() => {
              setCurrentTab(PATIENT);
            }}
          >
            <IconButton color="secondary">
              <SendIcon />
            </IconButton>
          </Link>
        );
      },
    },
  ];

  return (
    <div className="main-content">
      <div className="dashboard">
        <div className="container" style={{ flexDirection: "row" }}>
          <div className="left">
            <div className="colorfulNumBox">
              <div
                className="number"
                style={{
                  color: "blue",
                }}
              >
                129
              </div>
              <div className="description">Patients in total</div>
            </div>
            <div className="colorfulNumBox">
              <div
                className="number"
                style={{
                  color: "orange",
                }}
              >
                69
              </div>
              <div className="description">Left THA patients</div>
            </div>
            <div className="colorfulNumBox">
              <div
                className="number"
                style={{
                  color: "purple",
                }}
              >
                60
              </div>
              <div className="description">Left TKA patients</div>
            </div>
          </div>
          <div
            className="right"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              margin: "0 5px",
            }}
          >
            <Bar options={options} data={data} />
          </div>
        </div>
        <div className="container" style={{ textAlign: "center" }}>
          <div className="dashboard-title">Notification</div>
          <Alert
            className="notification-container"
            severity="info"
            style={{ margin: "5px 5%", width: "86%", padding: "6px 2%" }}
          >
            You receive an X-ray from patient C
          </Alert>
          <Alert
            className="notification-container"
            severity="warning"
            style={{ margin: "5px 5%", width: "86%", padding: "6px 2%" }}
          >
            Patinet A got an open wound!
          </Alert>
        </div>
        <div className="container" style={{ height: "380px" }}>
          <div className="dashboard-title">
            Starred patients
            <Link
              to={PATIENT_LIST}
              onClick={() => {
                setCurrentTab(PATIENT_LIST);
              }}
              title={"show all patients"}
            >
              <FormatListBulleted
                style={{ cursor: "pointer", color: "black" }}
              ></FormatListBulleted>
            </Link>
          </div>
          <div
            className="patient-list"
            style={{ backgroundColor: "#eef2f5", height: "300px" }}
          >
            <div
              className="table"
              style={{
                margin: "5px 5%",
                height: "280px",
              }}
            >
              <DataGrid
                rows={rows}
                columns={columns}
                getRowClassName={(e) => {
                  return `table-cell ${e.id % 2 === 1 ? "odd" : "even"}`;
                }}
              ></DataGrid>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="dashboard-title">Contact us</div>
          <div className="dashboard-title contact ">
            <span>Watch &nbsp;Problem</span>
            <span>------</span>
            <span>XXX-XXX-XXXX</span>
          </div>
          <div className="dashboard-title contact">
            <span>Medical Problem</span>
            <span>------</span>
            <span>XXX-XXX-XXXX</span>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Dashboard;
