import { useState, useEffect } from "react";
import * as React from "react";
import {
  dummyData,
  LINE_CHART,
  WEEKLY_CHART,
} from "../../../../constants/const";
import TitleBar from "../Title";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import "./index.css";
import { Icon, Modal, SvgIcon, Typography } from "@mui/material";
import { Box } from "@mui/system";
import BasicModal from "../../../../public_components/modal/modal";
import Mask from "../../../../public_components/mask/mask";
import { ColorLens, SwapHoriz } from "@mui/icons-material";

const GREEN = "#3FDA26";
const LIGHT_GREEN = "#C8FFD4";
const YELLOW = "#F9C74F";
const LIGHT_BLUE = "#43B0F1";
const BLUE = "#057DCD";
const ORANGE = "#FF7723";
const RED = "#FF817B";
const GRAY = "gray";
const OTHER_COLOR = "#00BFFF";
const PURPLE = "rebeccapurple";
const COLOR1 = "#B2D8D8";
const COLOR2 = "#008080";
const COLOR3 = "#004C4C";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "white",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function WeekCalendar(props) {
  const {
    positionIndex,
    itemData,
    Initialtitle,
    InitialDate,
    InitialValue,
    displayContainer,
    setDisplayContainer,
    clickable = true,
  } = props;

  const [title, setTitle] = useState(Initialtitle);
  const [date, setDate] = useState(InitialDate);
  const [value, setValue] = useState(InitialValue);

  const [TEST_DATE, set_TEST_DATE] = useState([]);
  const [TEST_WEEK, set_TEST_WEEK] = useState([]);
  const [currentWeek, setCurrentWeek] = useState(4);

  const [dateMenuOpen, setDateMenuOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [maskOpen, setMaskOpen] = useState(false);

  const [colorScheme, setColorScheme] = useState(0);
  const colorSeries = [
    [COLOR1, COLOR2, COLOR3],
    [RED, GREEN, YELLOW],
    [BLUE, COLOR2, COLOR1],
  ];

  function swapToLineChart() {
    var temp = [...displayContainer];
    temp[positionIndex].type = LINE_CHART;
    setDisplayContainer(temp);
  }

  //temp week date
  useEffect(() => {
    let temp_date = [];
    let temp_week = [];
    for (let i = 0; i < 4; i++) {
      temp_date.push([]);
      for (let j = i * 7 + 4; j <= i * 7 + 4 + 6; j++) {
        temp_date[i].push(`8/${j}`);
      }
      temp_week.push(`8/${i * 7 + 4} - 8/${i * 7 + 4 + 6}`);
    }
    temp_date.push(["9/1", "9/2", "9/3", "9/4", "9/5", "9/6", "9/7"]);
    temp_week.push("9/1 - 9/7");
    set_TEST_DATE(temp_date);
    set_TEST_WEEK(temp_week);

    //if line chart is able to swap to weekly chart
    if (itemData.textValue !== undefined) {
      setValue(itemData.textValue);
    }
  }, []);

  //set mask automatically open while dropdown menu is open
  useEffect(() => {
    if (dateMenuOpen) {
      setMaskOpen(true);
    }
  }, [dateMenuOpen]);

  //find unique legends
  const legend = value.filter((v, i, a) => a.indexOf(v) === i);
  //TODO value needs to convert to number, it will be easy to check what legend and what color

  function setLegendColor(value) {
    if (itemData.severeDegree === undefined) return GRAY;
    switch (legend.length) {
      case 2:
        switch (itemData.severeDegree[value]) {
          case 1:
            return colorSeries[colorScheme][0];
          case 2:
            return colorSeries[colorScheme][1];
          default:
            return GRAY;
        }
      default:
        if (itemData.severeDegree[value] >= 3)
          return colorSeries[colorScheme][0];
        else if (itemData.severeDegree[value] == 2)
          return colorSeries[colorScheme][1];
        else if (itemData.severeDegree[value] == 1)
          return colorSeries[colorScheme][2];
        else {
          return GRAY;
        }
    }
  }

  function handleAnnotationColor() {
    let colorIndex = colorScheme;
    if (colorIndex == colorSeries.length - 1) {
      colorIndex = 0;
    } else {
      colorIndex++;
    }
    setColorScheme(colorIndex);
  }

  return (
    <div className="weekCalendar">
      <div className="header-top-left">
        {/* <ColorLens
          color="primary"
          onClick={() => {
            handleAnnotationColor();
          }}
        ></ColorLens> */}
        {itemData.textValue !== undefined ? (
          <SwapHoriz
            color="primary"
            onClick={() => {
              swapToLineChart();
            }}
          ></SwapHoriz>
        ) : (
          ""
        )}
      </div>
      <TitleBar
        positionIndex={positionIndex}
        title={title}
        setTitle={setTitle}
        setDate={setDate}
        setValue={setValue}
        displayContainer={displayContainer}
        setDisplayContainer={setDisplayContainer}
        clickable={clickable}
      ></TitleBar>
      <div className="calendar">
        {value.map((item, index) => {
          return (
            <div
              key={index}
              className="calendarContainer"
              style={{
                backgroundColor: setLegendColor(item),
                color: "white",
              }}
            >
              <div className="date" key={"date" + index}>
                {date.filter((item, i) => {
                  return i === index;
                })}
              </div>
              <div className="value" key={"value" + index}>
                {item}
              </div>
            </div>
          );
        })}
      </div>
      <div className="footer">
        <ArrowBackIcon
          className="arrow"
          onClick={() => {
            if (currentWeek == 0) {
              setModalOpen(true);
            } else {
              setDate(TEST_DATE[currentWeek - 1]);
              setCurrentWeek(currentWeek - 1);
            }
          }}
        ></ArrowBackIcon>
        <div
          className="date"
          onClick={() => {
            setDateMenuOpen(!dateMenuOpen);
          }}
        >
          {TEST_WEEK[currentWeek]}
        </div>
        {dateMenuOpen ? (
          <div
            className="dropdown"
            style={{
              marginTop: "25px",
            }}
          >
            {TEST_WEEK.map((item, index) => {
              return (
                <div
                  id={`positionIndex` + positionIndex + `dateMenu` + index}
                  className="menu"
                  key={index}
                  onClick={() => {
                    setDateMenuOpen(false);
                    setDate(TEST_DATE[index]);
                    setCurrentWeek(index);
                  }}
                >
                  {item}
                </div>
              );
            })}
          </div>
        ) : (
          ""
        )}
        <ArrowForwardIcon
          className="arrow"
          onClick={() => {
            if (currentWeek >= TEST_DATE.length - 1) {
              setModalOpen(true);
            } else {
              setDate(TEST_DATE[currentWeek + 1]);
              setCurrentWeek(currentWeek + 1);
            }
          }}
        ></ArrowForwardIcon>
      </div>
      <BasicModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        Title={"No mode data shown"}
      ></BasicModal>
      <Mask
        maskOpen={maskOpen}
        setMaskOpen={setMaskOpen}
        setOtherOpen={setDateMenuOpen}
      ></Mask>
    </div>
  );
}
