import { FormatListBulleted } from "@mui/icons-material";
import { Alert } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { PATIENT_LIST } from "../../constants/const";
import { Button } from "@mui/material";
import { modifyNavBar } from "../../util/commonFunctions";
import "./index.css";

export default function Notification(props) {
  const { setCurrentTab } = props;

  useEffect(() => {
    modifyNavBar(window.location.pathname, setCurrentTab);
  }, []);

  const location = useLocation();
  const patientData = location.state;

  return (
    <>
      {patientData ? (
        <div className="main-content">
          <div className="notification">
            <div className="list-title">Notification</div>
            {!patientData ? (
              <>
                <h2 className="list-title">All patients' notification</h2>
                <Alert className="notification-container" severity="success">
                  <span>Patient D</span> got an open wound
                </Alert>
                <Alert className="notification-container" severity="warning">
                  <span>Patient A</span>'s x-ray is ready to print
                </Alert>
                <Alert className="notification-container" severity="success">
                  <span>Patient S</span> has been recovered
                </Alert>
                <Alert className="notification-container" severity="error">
                  <span>Patient B</span> got an open wound
                </Alert>
                <Alert className="notification-container" severity="info">
                  <span>Patient C</span>'s x-ray is ready to print
                </Alert>
                <Alert className="notification-container" severity="success">
                  <span>Patient E</span> has been recovered
                </Alert>
              </>
            ) : (
              <>
                <h2 className="list-title">{patientData.patientName}</h2>
                <Alert className="notification-container" severity="error">
                  {patientData.patientName} got an open wound
                </Alert>
                <Alert className="notification-container" severity="info">
                  {patientData.patientName}'s x-ray is ready to print
                </Alert>
                <Alert className="notification-container" severity="success">
                  {patientData.patientName} has been recovered
                </Alert>
              </>
            )}
          </div>
        </div>
      ) : (
        <div
          style={{
            width: "85%",
            textAlign: "center",
            fontWeight: "700",
            fontSize: "25",
          }}
        >
          <div style={{ margin: "10% 0 5% 0" }}>
            Please select a particular patient to see their notifications
            <div>Click the button below navigating to patient list</div>
          </div>
          <div></div>
          <Link
            to={PATIENT_LIST}
            onClick={() => {
              setCurrentTab(PATIENT_LIST);
            }}
          >
            <Button variant="contained">
              <FormatListBulleted></FormatListBulleted>
            </Button>
          </Link>
        </div>
      )}
    </>
  );
}
