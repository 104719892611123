import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import "./index.css";
import annotationPlugin from "chartjs-plugin-annotation";
import zoomPlugin from "chartjs-plugin-zoom";
import {
  DEFAULT_HTML_FONT_FAMILY,
  WEEKLY_CHART,
} from "../../../../constants/const";
import TitleBar from "../Title";
import { Button } from "@mui/material";
import { ColorLens, SwapHoriz } from "@mui/icons-material";

export default function LineChart(props) {
  const {
    positionIndex,
    itemData,
    InitialTitle,
    InitialDate,
    InitialValue,
    InitialLegend,
    displayContainer,
    setDisplayContainer,
    clickable = true,
  } = props;

  const [title, setTitle] = useState(InitialTitle);
  const [date, setDate] = useState(InitialDate);
  const [value, setValue] = useState(InitialValue);
  const [legend, setLegend] = useState(InitialLegend);

  //control annotation line
  const [averageBeforeSurgeryDisplay, setAverageBeforeSurgeryDisplay] =
    useState(true);
  const [averageOverWeekDisplay, setAverageOverWeekDisplay] = useState(true);
  const [procedureDateDisplay, setProcedureDateDisplay] = useState(true);

  const GREEN = "#3FDA26";
  const LIGHT_GREEN = "#C8FFD4";
  const YELLOW = "#FFE15D";
  const LIGHT_BLUE = "#B8E8FC";
  const BLUE = "rgb(61, 178, 255,0.8)";
  const ORANGE = "#FF7723";
  const RED = "rgb(220, 53, 53,0.8)";
  const GRAY = "gray";
  const OTHER_COLOR = "#00BFFF";
  const PURPLE = "#B1AFFF";

  //test siwtch color scheme
  const [colorScheme, setColorScheme] = useState(1);
  const colorSeries = [
    ["green", YELLOW, RED],
    ["red", "black", "black"],
  ];

  function handleAnnotationColor() {
    let colorIndex = colorScheme;
    if (colorIndex == colorSeries.length - 1) {
      colorIndex = 0;
    } else {
      colorIndex++;
    }
    setColorScheme(colorIndex);
  }

  function swapToWeekChart() {
    var temp = [...displayContainer];
    temp[positionIndex].type = WEEKLY_CHART;
    setDisplayContainer(temp);
  }

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    zoomPlugin,
    annotationPlugin
  );

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        ticks: {
          color: "black",
        },
      },
      x: {
        ticks: {
          color: "black",
        },
      },
    },
    plugins: {
      legend: {
        position: "bottom",
        labels: {
          color: "black",
        },
        onClick: function (e, legendItem, legend) {
          const index = legendItem.datasetIndex;
          const ci = legend.chart;
          if (ci.isDatasetVisible(index)) {
            ci.hide(index);
            legendItem.hidden = true;
          } else {
            ci.show(index);
            legendItem.hidden = false;
          }
          if (index === 1) {
            setProcedureDateDisplay(!procedureDateDisplay);
          } else if (index === 2) {
            setAverageBeforeSurgeryDisplay(!averageBeforeSurgeryDisplay);
          } else if (index === 3) {
            setAverageOverWeekDisplay(!averageOverWeekDisplay);
          }
        },
      },
      title: {
        display: false,
        text: title,
        color: "black",
        font: {
          weight: 700,
          size: 16,
          family: DEFAULT_HTML_FONT_FAMILY,
        },
      },
      zoom: {
        zoom: {
          wheel: {
            enabled: true,
            speed: 0.1,
          },
          pinch: {
            enabled: true,
          },
          mode: "x",
        },
      },
      annotation: {
        annotations: [
          {
            display: procedureDateDisplay,
            type: "line",
            xMin: "9/5",
            xMax: "9/5",
            borderColor: colorSeries[colorScheme][0],
            borderWidth: 4,
            adjustScaleRange: false,
            label: {
              display: false,
              content: "procedure date",
              position: "end",
            },
          },
          {
            display: averageBeforeSurgeryDisplay,
            type: "line",
            yMin:
              value.reduce((a, b) => a + b, 0) / value.length >= 1000
                ? 8000
                : 8,
            yMax:
              value.reduce((a, b) => a + b, 0) / value.length >= 1000
                ? 8000
                : 8,
            borderColor: colorSeries[colorScheme][1],
            borderWidth: 4,
            adjustScaleRange: false,
            label: {
              display: false,
              content: "Average before surgery",
              position: "10%",
            },
          },
          {
            display: averageOverWeekDisplay,
            type: "line",
            yMin:
              value.reduce((a, b) => a + b, 0) / value.length >= 1000
                ? 4000
                : 4,
            yMax:
              value.reduce((a, b) => a + b, 0) / value.length >= 1000
                ? 4000
                : 4,
            borderColor: colorSeries[colorScheme][2],
            borderDash: [10],
            borderWidth: 4,
            adjustScaleRange: false,
            label: {
              display: false,
              content: "Average over this week",
              position: "end",
            },
          },
        ],
      },
    },
  };

  const data = {
    labels: date,
    datasets: [
      {
        label: legend,
        data: value,
        borderColor: "rgb(135,206,250)",
        backgroundColor: "rgb(135,206,250)",
        pointBackgroundColor: "white",
        spanGaps: false,
        borderWidth: 4,
      },
      {
        label: "procedure date",
        data: [],
        backgroundColor: colorSeries[colorScheme][0],
        pointBackgroundColor: "white",
        spanGaps: false,
        borderWidth: 2,
      },
      {
        label: "Average before surgery",
        data: [],
        backgroundColor: colorSeries[colorScheme][1],
        spanGaps: false,
        borderWidth: 2,
      },
      {
        label: "Average over this week",
        data: [],
        backgroundColor:
          colorScheme == 1 ? "white" : colorSeries[colorScheme][2],
        borderColor: colorScheme == 1 ? "black" : colorSeries[colorScheme][2],
        borderDash: [5],
        spanGaps: false,
        borderWidth: 2,
      },
    ],
  };

  return (
    //TODO custom legend, don't use existed legend
    <div className="LineChart">
      <div className="header-top-left">
        {/* <ColorLens
          color="primary"
          onClick={() => {
            handleAnnotationColor();
          }}
        ></ColorLens> */}
        {itemData.textValue !== undefined ? (
          <SwapHoriz
            color="primary"
            onClick={() => {
              swapToWeekChart();
            }}
          ></SwapHoriz>
        ) : (
          ""
        )}
      </div>
      <TitleBar
        positionIndex={positionIndex}
        title={title}
        setTitle={setTitle}
        setDate={setDate}
        setValue={setValue}
        setLegend={setLegend}
        displayContainer={displayContainer}
        setDisplayContainer={setDisplayContainer}
        clickable={clickable}
      ></TitleBar>
      <div style={{ height: "250px", width: "100%" }}>
        <Line options={options} data={data} />
      </div>
    </div>
  );
}
