import React, { useState, useEffect } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import { DEFAULT_HTML_FONT_FAMILY } from "../../../../constants/const";
import TitleBar from "../Title";
import { width } from "@mui/system";
import { FastfoodOutlined } from "@mui/icons-material";

export default function PieChart(props) {
  const {
    positionIndex,
    itemData,
    InitialTitle,
    InitialDate,
    InitialValue,
    InitialLegend,
    displayContainer,
    setDisplayContainer,
    clickable = true,
  } = props;

  const [title, setTitle] = useState(InitialTitle);
  const [date, setDate] = useState(InitialDate);
  const [value, setValue] = useState(InitialValue);
  const [legend, setLegend] = useState(InitialLegend);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "right",
        labels: {
          color: "black",
        },
      },
      title: {
        display: false,
        text: title,
        color: "black",
        font: {
          weight: 700,
          size: 16,
          family: DEFAULT_HTML_FONT_FAMILY,
        },
      },
    },
  };

  const data = {
    labels: legend,
    datasets: [
      {
        label: "# of Votes",
        data: value,
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
          "rgba(218, 112, 214,0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
          "rgba(218, 112, 214,1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  ChartJS.register(ArcElement, Tooltip, Legend);

  return (
    <div className="PieChart">
      {/* <TitleBar
        positionIndex={positionIndex}
        title={title}
        setTitle={setTitle}
        setDate={setDate}
        setValue={setValue}
        setLegend={setLegend}
        displayContainer={displayContainer}
        setDisplayContainer={setDisplayContainer}
        clickable={clickable}
      ></TitleBar> */}
      <div
        style={{
          height: "250px",
        }}
      >
        <Pie data={data} options={options} width={300} height={300}></Pie>
      </div>
    </div>
  );
}
