export const PATIENT_LIST = "/patientList";
export const DASHBOARD = "/";
export const NOTIFICATION = "/notification";
export const PATIENT = "/patient";

export const LINE_CHART = 1;
export const WEEKLY_CHART = 2;
export const PIE_CHART = 3;
export const TABLE_CHART = 4;

//run data preparation functions
main();

//prepare data functions
function main() {
  // console.log("running const.js");

  // console.log("const.js ends");
  return;
}

export const PROCEDURE_TYPE = {
  LEFT_TKA: {
    fullName: "Left Total Knee Arthroplasty",
    abbr: "Left TKA",
    id: 1,
  },
  RIGHT_TKA: {
    fullName: "Right Total Knee Arthroplasty",
    abbr: "Right TKA",
    id: 2,
  },
  LEFT_THA: {
    fullName: "Left Total Hip Arthroplasty",
    abbr: "Left THA",
    id: 3,
  },
  RIGHT_THA: {
    fullName: "Right Total Hip Arthroplasty",
    abbr: "Right THA",
    id: 4,
  },
  OTHERS: {
    fullName: "Others - TBD",
    id: 5,
  },
};

export const DEFAULT_HTML_FONT_FAMILY =
  "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen','Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',sans-serif";

export var dummyData = [
  //severeDegree -> level of severity. higher number means higher severity.
  //low number ->  green; high number -> red.
  //normal range: 1 - 5
  //if degree is 0, means N/A
  {
    title: "Current pain?",
    date: ["9/1", "9/2", "9/3", "9/4", "9/5", "9/6", "9/7"],
    value: [0, 4, 1, 10, null, 8, 6],
    textValue: [
      "None",
      "Moderate",
      "Mild",
      "Worst possible",
      "N/A",
      "Very severe",
      "Severe",
    ],
    severeDegree: {
      None: 1,
      Moderate: 2,
      Mild: 3,
      "Worst possible": 4,
      "Very severe": 5,
      Severe: 6,
    },
    legend: "9/1 week",
    type: LINE_CHART,
  },
  {
    title: "Daily Step Count",
    date: ["9/1", "9/2", "9/3", "9/4", "9/5", "9/6", "9/7"],
    value: [2347, 3523, 5452, 7542, 9542, 9542, null],
    legend: "9/1 week",
    type: LINE_CHART,
  },
  {
    title: "Did you take non-narcotic pain medication today?",
    date: ["9/1", "9/2", "9/3", "9/4", "9/5", "9/6", "9/7"],
    value: ["Yes", "Yes", "No", "No", "Yes", "No", "Yes"],
    severeDegree: {
      Yes: 2,
      No: 1,
    },
    type: WEEKLY_CHART,
  },
  {
    title: "Did you take narcotic pain medications today?",
    date: ["9/1", "9/2", "9/3", "9/4", "9/5", "9/6", "9/7"],
    value: ["Yes", "No", "Yes", "No", "Yes", "No", "No"],
    severeDegree: {
      Yes: 2,
      No: 1,
    },
    type: WEEKLY_CHART,
  },
  {
    title: "Did you take blood thinner medicine today?",
    date: ["9/1", "9/2", "9/3", "9/4", "9/5", "9/6", "9/7"],
    value: ["Yes", "No", "No", "No", "Yes", "No", "Yes"],
    severeDegree: {
      Yes: 2,
      No: 1,
    },
    type: WEEKLY_CHART,
  },
  {
    title: "Did you do your home rehab exercises?",
    date: ["9/1", "9/2", "9/3", "9/4", "9/5", "9/6", "9/7"],
    value: ["All", "None", "Some", "None", "All", "Some", "None"],
    severeDegree: {
      All: 1,
      Some: 2,
      None: 3,
    },
    type: WEEKLY_CHART,
  },
  {
    title: "How much are you using a walking aid (e.g., cane/walker)?",
    date: ["9/1", "9/2", "9/3", "9/4", "9/5", "9/6", "9/7"],
    value: [
      "A little",
      "None",
      "Some",
      "A lot",
      "All the time",
      "Some",
      "None",
    ],
    severeDegree: {
      "A little": 2,
      None: 1,
      Some: 3,
      "A lot": 4,
      "All the time": 5,
    },
    type: WEEKLY_CHART,
  },
  {
    title: "Redness spreading away from wound?",
    date: ["9/1", "9/2", "9/3", "9/4", "9/5", "9/6", "9/7"],
    value: [
      "Not at all",
      "A little",
      "Quite a bit",
      "A lot",
      "A little",
      "Quite a bit",
      "A lot",
    ],
    severeDegree: {
      "Not at all": 1,
      "A little": 2,
      "Quite a bit": 3,
      "A lot": 4,
    },
    type: WEEKLY_CHART,
  },
  {
    title: "Fever or raised temperature? ",
    date: ["9/1", "9/2", "9/3", "9/4", "9/5", "9/6", "9/7"],
    value: [
      "Not at all",
      "A little",
      "Quite a bit",
      "A lot",
      "A little",
      "Quite a bit",
      "Not at all",
    ],
    severeDegree: {
      "Not at all": 1,
      "A little": 2,
      "Quite a bit": 3,
      "A lot": 4,
    },
    type: WEEKLY_CHART,
  },
  {
    title: "In the past week, have you cut down on your normal activity?",
    date: ["9/1", "9/2", "9/3", "9/4", "9/5", "9/6", "9/7"],
    value: ["None", "A little", "A lot", "Some", "A little", "A lot", "None"],
    severeDegree: {
      "A little": 2,
      None: 1,
      Some: 3,
      "A lot": 4,
    },
    type: WEEKLY_CHART,
  },
  {
    title: "Any medical care that is related to the surgery in past week?",
    date: ["9/1", "9/2", "9/3", "9/4", "9/5", "9/6", "9/7"],
    value: [
      "Routine",
      "None",
      "afterhours",
      "Hospitalized",
      "Routine",
      "afterhours",
      "None",
    ],
    severeDegree: {
      Routine: 1,
      None: 3,
      afterhours: 4,
      Hospitalized: 2,
    },
    type: WEEKLY_CHART,
  },
  {
    title: "Any medical care that is unrelated to the surgery in past week?",
    date: ["9/1", "9/2", "9/3", "9/4", "9/5", "9/6", "9/7"],
    value: [
      "Routine",
      "None",
      "afterhours",
      "Hospitalized",
      "Routine",
      "afterhours",
      "None",
    ],
    severeDegree: {
      Routine: 1,
      None: 3,
      afterhours: 4,
      Hospitalized: 2,
    },
    type: WEEKLY_CHART,
  },
  {
    title: "Since last report, did you have a fall but DID NOT cause injury",
    legend: ["Mon", "Tue", "Wed", "Th", "Fr", "Sat", "Sun"],
    date: ["9/1", "9/2", "9/3", "9/4", "9/5", "9/6", "9/7"],
    severeDegree: {
      Routine: 1,
      None: 3,
      afterhours: 4,
      Hospitalized: 2,
    },
    value: [
      "Routine",
      "None",
      "afterhours",
      "Hospitalized",
      "Routine",
      "afterhours",
      "None",
    ],
    type: WEEKLY_CHART,
  },
  {
    title: "Since last report, did you have a fall but DID cause injury?",
    legend: ["Mon", "Tue", "Wed", "Th", "Fr", "Sat", "Sun"],
    date: ["9/1", "9/2", "9/3", "9/4", "9/5", "9/6", "9/7"],
    severeDegree: {
      Routine: 1,
      None: 3,
      afterhours: 4,
      Hospitalized: 2,
    },
    value: [
      "Routine",
      "None",
      "afterhours",
      "Hospitalized",
      "Routine",
      "afterhours",
      "None",
    ],
    type: WEEKLY_CHART,
  },
  {
    title: "Having issues with constipation in the past week?",
    date: ["9/1", "9/2", "9/3", "9/4", "9/5", "9/6", "9/7"],
    value: [
      "A little",
      "None",
      "Some",
      "A lot",
      "All the time",
      "Some",
      "None",
    ],
    severeDegree: {
      "A little": 2,
      None: 1,
      Some: 3,
      "A lot": 4,
      "All the time": 5,
    },
    type: WEEKLY_CHART,
  },
  {
    title: "Leaked blood-stained fluid from wound?",
    date: ["9/1", "9/2", "9/3", "9/4", "9/5", "9/6", "9/7"],
    value: ["Not at all", "A little", "Quite a bit", "A lot"],
    severeDegree: {
      "Not at all": 1,
      "A little": 2,
      "Quite a bit": 3,
      "A lot": 4,
    },
    type: WEEKLY_CHART,
  },
  {
    title: "Edges of wound separated/gaped open? ",
    date: ["9/1", "9/2", "9/3", "9/4", "9/5", "9/6", "9/7"],
    value: ["Not at all", "A little", "Quite a bit", "A lot"],
    severeDegree: {
      "Not at all": 1,
      "A little": 2,
      "Quite a bit": 3,
      "A lot": 4,
    },
    type: WEEKLY_CHART,
  },
  {
    title: "Difficulty walking across a room?",
    date: ["9/1", "9/2", "9/3", "9/4", "9/5", "9/6", "9/7"],
    value: [
      "None",
      "Some",
      "A lot",
      "Unable or need help",
      "did not do",
      "A lot",
      "Unable or need help",
    ],
    severeDegree: {
      None: 1,
      Some: 2,
      "A lot": 3,
      "Unable or need help": 4,
      "did not do": 0,
    },
    type: WEEKLY_CHART,
  },
  {
    title: "Difficulty walking a block?",
    date: ["9/1", "9/2", "9/3", "9/4", "9/5", "9/6", "9/7"],
    value: [
      "None",
      "Some",
      "A lot",
      "Unable or need help",
      "did not do",
      "A lot",
      "Unable or need help",
    ],
    severeDegree: {
      None: 1,
      Some: 2,
      "A lot": 3,
      "Unable or need help": 4,
      "did not do": 0,
    },
    type: WEEKLY_CHART,
  },
  {
    title: "Difficulty bathing/showering?",
    date: ["9/1", "9/2", "9/3", "9/4", "9/5", "9/6", "9/7"],
    value: [
      "None",
      "Some",
      "A lot",
      "Unable or need help",
      "did not do",
      "A lot",
      "Unable or need help",
    ],
    severeDegree: {
      None: 1,
      Some: 2,
      "A lot": 3,
      "Unable or need help": 4,
      "did not do": 0,
    },
    type: WEEKLY_CHART,
  },
  {
    title: "Did you wear your compression socks today?",
    date: ["9/1", "9/2", "9/3", "9/4", "9/5", "9/6", "9/7"],
    value: ["Yes", "No", "No", "No", "Yes", "No", "Yes"],
    severeDegree: {
      Yes: 2,
      No: 1,
    },
    type: WEEKLY_CHART,
  },
  {
    title: "Difficulty getting on or off the toilet?",
    date: ["9/1", "9/2", "9/3", "9/4", "9/5", "9/6", "9/7"],
    value: [
      "None",
      "Some",
      "A lot",
      "Unable or need help",
      "did not do",
      "A lot",
      "Unable or need help",
    ],
    severeDegree: {
      None: 1,
      Some: 2,
      "A lot": 3,
      "Unable or need help": 4,
      "did not do": 0,
    },
    type: WEEKLY_CHART,
  },
  {
    title: "Difficulty dressing?",
    date: ["9/1", "9/2", "9/3", "9/4", "9/5", "9/6", "9/7"],
    value: [
      "None",
      "Some",
      "A lot",
      "Unable or need help",
      "did not do",
      "A lot",
      "Unable or need help",
    ],
    severeDegree: {
      None: 1,
      Some: 2,
      "A lot": 3,
      "Unable or need help": 4,
      "did not do": 0,
    },
    type: WEEKLY_CHART,
  },
  {
    title: "Difficulty moving in and out of a bed?",
    date: ["9/1", "9/2", "9/3", "9/4", "9/5", "9/6", "9/7"],
    value: [
      "None",
      "Some",
      "A lot",
      "Unable or need help",
      "did not do",
      "A lot",
      "Unable or need help",
    ],
    severeDegree: {
      None: 1,
      Some: 2,
      "A lot": 3,
      "Unable or need help": 4,
      "did not do": 0,
    },
    type: WEEKLY_CHART,
  },
  {
    title: "Difficulty getting in and out of a car?",
    date: ["9/1", "9/2", "9/3", "9/4", "9/5", "9/6", "9/7"],
    value: [
      "None",
      "Some",
      "A lot",
      "Unable or need help",
      "did not do",
      "A lot",
      "Unable or need help",
    ],
    severeDegree: {
      None: 1,
      Some: 2,
      "A lot": 3,
      "Unable or need help": 4,
      "did not do": 0,
    },
    type: WEEKLY_CHART,
  },
  {
    title: "Current fatigue?",
    date: ["9/1", "9/2", "9/3", "9/4", "9/5", "9/6", "9/7"],
    value: [2, 3, 5, 7, 5, 8, 0],
    textValue: [
      "Mild",
      "Mild",
      "Moderate",
      "Severe",
      "Moderate",
      "Very severe",
      "None",
    ],
    severeDegree: {
      None: 1,
      Moderate: 2,
      Mild: 3,
      "Worst possible": 4,
      "Very severe": 5,
      Severe: 6,
    },
    legend: "9/1 week",
    type: LINE_CHART,
  },
  {
    title: "Current mood?",
    date: ["9/1", "9/2", "9/3", "9/4", "9/5", "9/6", "9/7"],
    value: [2, 3, 5, 9, 0, 9, 6],
    textValue: [
      "Negative",
      "Negative",
      "Neutral",
      "Very positive",
      "Very negative",
      "Very positive",
      "Positive",
    ],
    severeDegree: {
      "Very positive": 1,
      Positive: 2,
      Neutral: 3,
      Negative: 4,
      "Very negative": 5,
    },
    legend: "9/1 week",
    type: LINE_CHART,
  },
  {
    title: "Sleep quality last night?",
    date: ["9/1", "9/2", "9/3", "9/4", "9/5", "9/6", "9/7"],
    value: [7, 9, 5, 7, 9, 9, 10],
    textValue: [
      "Well",
      "Very well",
      "Well",
      "Well",
      "Very well",
      "Very well",
      "Very well",
    ],
    severeDegree: {
      "Very well": 1,
      Well: 2,
      OK: 3,
      Poor: 4,
      "Very poor": 5,
    },
    legend: "9/1 week",
    type: LINE_CHART,
  },
  {
    title: "How many days this week did you drive?",
    date: ["9/1", "9/2", "9/3", "9/4", "9/5", "9/6", "9/7"],
    value: [2, 3, 4, 1, 3, 2, 0],
    legend: "9/1 week",
    type: LINE_CHART,
  },
  {
    title: "What is your current activity? ",
    date: ["9/1", "9/2", "9/3", "9/4", "9/5", "9/6", "9/7"],
    value: [
      "Wholly inactive",
      "minimum activity",
      "some mild activity",
      "regular mild activity",
      "some moderate activity",
      "regular moderate activity",
      "regular active events",
    ],
    severeDegree: {
      "Wholly inactive": 7,
      "minimum activity": 6,
      "some mild activity": 5,
      "regular mild activity": 4,
      "some moderate activity": 3,
      "regular moderate activity": 2,
      "regular active events": 1,
    },
    type: WEEKLY_CHART,
  },
  {
    title: "Quality of your driving in past 3 days? ",
    date: ["9/1", "9/2", "9/3", "9/4", "9/5", "9/6", "9/7"],
    value: ["Did not drive", "Excellent", "good", "average", "fair", "poor"],
    severeDegree: {
      "Did not drive": 0,
      Excellent: 1,
      good: 2,
      average: 3,
      fair: 4,
      poor: 5,
    },
    type: WEEKLY_CHART,
  },
];
