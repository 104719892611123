import * as React from "react";
import "./mask.css";

export default function Mask(props) {
  const { maskOpen, setMaskOpen, setOtherOpen } = props;

  return (
    <div>
      {maskOpen ? (
        <div
          className="mask"
          onClick={() => {
            setMaskOpen(false);
            setOtherOpen(false);
          }}
        ></div>
      ) : (
        ""
      )}
    </div>
  );
}
