import {
  PIE_CHART,
  dummyData,
  LINE_CHART,
  WEEKLY_CHART,
  PATIENT_LIST,
} from "../../constants/const";
import "./index.css";
import * as React from "react";
import LineChart from "./components/LineChart";
import PieChart from "./components/PieChart";
import WeekCalendar from "./components/WeekCalendar";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Button } from "@mui/material";
import {
  FormatListBulleted,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@mui/icons-material";
import { modifyNavBar } from "../../util/commonFunctions";

function Patient(props) {
  const { setCurrentTab } = props;
  const location = useLocation();
  const patientData = location.state;
  const data = dummyData;

  useEffect(() => {
    modifyNavBar(window.location.pathname, setCurrentTab);
  }, []);

  const [displayContainer, setDisplayContainer] = useState(data.slice(0, 11));

  const [patientDetail, setPatientDetail] = useState(false);

  return (
    <>
      {patientData ? (
        <div className="main-content">
          <div className="patient">
            <div className="container title">
              <div className="left">{patientData.patientName}</div>
              <div className="right">
                <div>Procedure: {patientData.type}</div>
                <div>Procedure Date: {patientData.date}</div>
                <Button
                  onClick={() => {
                    setPatientDetail(!patientDetail);
                  }}
                  style={{ padding: "0 50px" }}
                >
                  {patientDetail ? (
                    <KeyboardArrowUp
                      style={{ color: "black" }}
                    ></KeyboardArrowUp>
                  ) : (
                    <KeyboardArrowDown
                      style={{ color: "black" }}
                    ></KeyboardArrowDown>
                  )}
                </Button>
                {patientDetail ? (
                  <>
                    <div>Surgery: {patientData.surgery}</div>
                    <div>Birth Date: 01/01/2023</div>
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div id="left" className="column">
              {displayContainer.map((item, index) => {
                //display even index plots
                if (index % 2 === 0) {
                  return (
                    <div
                      key={index}
                      className={`container ${
                        item.type !== WEEKLY_CHART ? "line-chart" : ""
                      }`}
                    >
                      {item.type === LINE_CHART ? (
                        <LineChart
                          positionIndex={index}
                          itemData={item}
                          InitialTitle={item.title}
                          InitialDate={item.date}
                          InitialValue={item.value}
                          InitialLegend={item.legend}
                          displayContainer={displayContainer}
                          setDisplayContainer={setDisplayContainer}
                          clickable={
                            index === displayContainer.length - 1 ? true : false
                          }
                        ></LineChart>
                      ) : item.type === WEEKLY_CHART ? (
                        <WeekCalendar
                          positionIndex={index}
                          itemData={item}
                          Initialtitle={item.title}
                          InitialDate={item.date}
                          InitialValue={item.value}
                          displayContainer={displayContainer}
                          setDisplayContainer={setDisplayContainer}
                          clickable={
                            index === displayContainer.length - 1 ? true : false
                          }
                        ></WeekCalendar>
                      ) : item.type === PIE_CHART ? (
                        <PieChart
                          positionIndex={index}
                          itemData={item}
                          InitialTitle={item.title}
                          InitialDate={item.date}
                          InitialValue={item.value}
                          InitialLegend={item.legend}
                          displayContainer={displayContainer}
                          setDisplayContainer={setDisplayContainer}
                          clickable={
                            index === displayContainer.length - 1 ? true : false
                          }
                        ></PieChart>
                      ) : (
                        ""
                      )}
                    </div>
                  );
                } else {
                  return <div key={index}></div>;
                }
              })}
            </div>
            <div id="right" className="column">
              {displayContainer.map((item, index) => {
                //display odd index plots
                if (index % 2 === 1) {
                  return (
                    <div
                      key={index}
                      className={`container ${
                        item.type !== WEEKLY_CHART ? "line-chart" : ""
                      }`}
                    >
                      {item.type === LINE_CHART ? (
                        <LineChart
                          positionIndex={index}
                          itemData={item}
                          InitialTitle={item.title}
                          InitialDate={item.date}
                          InitialValue={item.value}
                          InitialLegend={item.legend}
                          displayContainer={displayContainer}
                          setDisplayContainer={setDisplayContainer}
                          clickable={
                            index === displayContainer.length - 1 ? true : false
                          }
                        ></LineChart>
                      ) : item.type === WEEKLY_CHART ? (
                        <WeekCalendar
                          positionIndex={index}
                          itemData={item}
                          Initialtitle={item.title}
                          InitialDate={item.date}
                          InitialValue={item.value}
                          displayContainer={displayContainer}
                          setDisplayContainer={setDisplayContainer}
                          clickable={
                            index === displayContainer.length - 1 ? true : false
                          }
                        ></WeekCalendar>
                      ) : item.type === PIE_CHART ? (
                        <PieChart
                          positionIndex={index}
                          itemData={item}
                          InitialTitle={item.title}
                          InitialDate={item.date}
                          InitialValue={item.value}
                          InitialLegend={item.legend}
                          displayContainer={displayContainer}
                          setDisplayContainer={setDisplayContainer}
                          clickable={
                            index === displayContainer.length - 1 ? true : false
                          }
                        ></PieChart>
                      ) : (
                        ""
                      )}
                    </div>
                  );
                } else {
                  return <div key={index}></div>;
                }
              })}
            </div>
            <div style={{ height: "50px", width: "100%" }}></div>
          </div>
        </div>
      ) : (
        <div
          style={{
            width: "85%",
            textAlign: "center",
            fontWeight: "700",
            fontSize: "25",
          }}
        >
          <div style={{ margin: "10% 0 5% 0" }}>
            Please select a particular patient to see detailed information
            <div>Click the button below navigating to patient list</div>
          </div>
          <div></div>
          <Link
            to={PATIENT_LIST}
            onClick={() => {
              setCurrentTab(PATIENT_LIST);
            }}
          >
            <Button variant="contained">
              <FormatListBulleted></FormatListBulleted>
            </Button>
          </Link>
        </div>
      )}
    </>
  );
}
export default Patient;
