import "./index.css";
import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import SendIcon from "@mui/icons-material/Send";
import { IconButton } from "@mui/material";
import { NOTIFICATION, PATIENT, PROCEDURE_TYPE } from "../../constants/const";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { NotificationsNone } from "@mui/icons-material";
import { modifyNavBar } from "../../util/commonFunctions";

function List(props) {
  const { setCurrentTab } = props;

  const rows = [
    {
      id: 1,
      patientName: "Patient A",
      surgery: "name1",
      type: PROCEDURE_TYPE.LEFT_TKA.abbr,
      date: "9/14/2022",
      alert: false,
    },
    {
      id: 2,
      patientName: "Patient B",
      surgery: "name2",
      type: PROCEDURE_TYPE.LEFT_THA.abbr,
      date: "9/23/2022",
      alert: true,
    },
    {
      id: 3,
      patientName: "Patient C",
      surgery: "name3",
      type: PROCEDURE_TYPE.RIGHT_THA.abbr,
      date: "10/7/2022",
      alert: true,
    },
  ];

  const columns = [
    {
      field: "patientName",
      headerName: "Patient's Name",
      flex: 1,
      align: "center",
      headerAlign: "center",
      headerClassName: "table-header",
    },
    {
      field: "type",
      headerName: "Procedure",
      flex: 0.5,
      align: "center",
      headerAlign: "center",
      headerClassName: "table-header",
    },
    {
      field: "surgery",
      headerName: "Surgery",
      flex: 0.5,
      align: "center",
      headerAlign: "center",
      headerClassName: "table-header",
    },
    {
      field: "date",
      headerName: "Procedure Date",
      flex: 0.5,
      align: "center",
      headerAlign: "center",
      headerClassName: "table-header",
    },
    {
      field: "alert",
      headerName: "Alert",
      flex: 0.3,
      align: "center",
      headerAlign: "center",
      headerClassName: "table-header",
      sortable: true,
      disableColumnMenu: true,
      renderCell: (cellValues) => {
        return (
          <>
            {cellValues.row.alert ? (
              <Link
                to={NOTIFICATION}
                state={{
                  patientName: cellValues.row.patientName,
                  type: cellValues.row.type,
                  date: cellValues.row.date,
                  surgery: cellValues.row.surgery,
                }}
                onClick={() => {
                  setCurrentTab(NOTIFICATION);
                }}
              >
                <IconButton color="secondary">
                  <NotificationsNone></NotificationsNone>
                </IconButton>
              </Link>
            ) : (
              ""
            )}
          </>
        );
      },
    },
    {
      field: "button",
      headerName: "",
      flex: 0.2,
      align: "center",
      headerAlign: "center",
      headerClassName: "table-header",
      sortable: false,
      disableColumnMenu: true,
      renderCell: (cellValues) => {
        return (
          <Link
            to="/patient"
            state={{
              patientName: cellValues.row.patientName,
              type: cellValues.row.type,
              date: cellValues.row.date,
              surgery: cellValues.row.surgery,
            }}
            onClick={() => {
              setCurrentTab(PATIENT);
            }}
          >
            <IconButton color="secondary">
              <SendIcon />
            </IconButton>
          </Link>
        );
      },
    },
  ];

  useEffect(() => {
    // connection();
    modifyNavBar(window.location.pathname, setCurrentTab);
  }, []);

  async function connection() {
    //TEST url
    //https://jsonplaceholder.typicode.com/users
    //CLIENT ID
    //16506df3-2c58-417e-8ba3-db00423231b3

    var clientId = "16506df3-2c58-417e-8ba3-db00423231b3";
    clientId = "16506df3-2c58-417e-8ba3-db00423231b3"; // Replace with your client id
    var redirect = "http://localhost:3000/";
    var url = `https://fhir.epic.com/interconnect-fhir-oauth/oauth2/authorize?scope=launch&response_type=code&redirect_uri=${redirect}&client_id=${clientId}&launch=${"launch"}&state=${"1234"}`;
    // url = `https://fhir.epic.com/interconnect-fhir-oauth/oauth2/authorize?response_type=code&redirect_uri=${redirect}&client_id=${clientId}&state=1234&scope=patient.read`;
    // url =
    //   "https://fhir.epic.com/interconnect-fhir-oauth/api/FHIR/DSTU2/metadata HTTP/1.1";

    // url =
    //   "https://fhir.epic.com/interconnect-fhir-oauth/api/FHIR/DSTU2/Patient?given=Derrick&family=Lin&birthdate=1973-06-03";

    try {
      const response = await fetch(url);
      console.log("connection successful", response);

      var tokenUrl =
        "https://fhir.epic.com/interconnect-fhir-oauth/oauth2/token";
      var params = {
        grant_type: "authorization_code",
        redirect_url: redirect,
        code: "",
        client_id: clientId,
        state: "1234",
      };
      var header = {
        "Content-Type": "application/x-www-form-urlencoded",
      };
      // const res = await fetch(tokenUrl, params);
      // console.log("token res", res);
    } catch (error) {
      console.log("connection error", error);
    }
  }

  return (
    <div className="main-content">
      <div className="patient-list">
        <div className="list-title">Patient List</div>
        <div className="table">
          <DataGrid
            rows={rows}
            columns={columns}
            getRowClassName={(e) => {
              return `table-cell ${e.id % 2 === 1 ? "odd" : "even"}`;
            }}
          ></DataGrid>
        </div>
      </div>
    </div>
  );
}
export default List;
